import React from 'react';
import './OurHistory.css';
import historyImage from './../../assets/images/historySectionImage.webp';

const OurHistory = () => {

  return (
    <div className='our-history-content-wrapper'>
        <div className='history-column'>
            <div className='history-column-header'>
                <h2>Our History</h2>
            </div>

            <div className='history-column-content'>
                <p>
                    We started in this industry in 1998. We began in Ohio and moved to Florida seeking warmer weather. We are a small, family-owned and operated company which means that we can control the quality level and prompt nature of each of our jobs.
                    <br/>
                    <br/>
                    Located in Ocklawaha, FL we proudly serve Marion, Lake, and Sumter Counties in Florida, ranging from Ocala to Leesburg with many installations in The Villages, we are proud of our extraordinary 5-star rating from hundreds of satisfied clients who have discovered that there is truly a difference when you have
                    <span style={{display: 'unset', fontStyle: 'italic'}}> Gutters By Watkins.</span>
                </p>
            </div>
        </div>
        <div className='history-column'>
            <div className='history-img-wrapper'>
                <img src={historyImage} alt='A Watkins Seamless Gutters truck parked on a residential street with a ladder leaning against it, preparing for a gutter installation. The truck is branded with company details, including contact information and services offered for seamless gutters.' height={'1080'} width={'1440'}></img>
            </div>
        </div>

    </div>
  );
};

export default OurHistory;
