import React, { useRef } from 'react';
import './Gallery.css'; // Import the stylesheet
import { Link } from 'react-router-dom';

import gallery1 from './../../assets/images/gallery/gallery1.webp';
import gallery2 from './../../assets/images/gallery/gallery2.webp';
import gallery3 from './../../assets/images/gallery/gallery3.webp';
import gallery4 from './../../assets/images/gallery/gallery4.webp';
import gallery5 from './../../assets/images/gallery/gallery5.webp';
import gallery6 from './../../assets/images/gallery/gallery6.webp';
import gallery7 from './../../assets/images/gallery/gallery7.webp';
import gallery8 from './../../assets/images/gallery/gallery8.webp';
import gallery9 from './../../assets/images/gallery/gallery9.webp';
import gallery10 from './../../assets/images/gallery/gallery10.webp';
import gallery11 from './../../assets/images/gallery/gallery11.webp';
import gallery12 from './../../assets/images/gallery/gallery12.webp';

import companyVideo from './../../assets/videos/infoVid.mp4';
import thumbnailImage from './../../assets/videos/videoThumbnail.PNG';

import { Helmet } from 'react-helmet-async';

const Gallery = () => {
    const videoRef = useRef(null);
    return (
        <>
            <Helmet>
                <title>Gallery | Marion County, FL | Watkins Seamless Gutters</title>
                <meta 
                    name="description" 
                    content="Watkins Seamless Gutters provides the best in gutter service, installation and repair in Marion County, Florida and beyond. Click here to see photos of our work." 
                />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Gallery | Marion County, FL | Watkins Seamless Gutters" />
                <meta name="twitter:description" content="Watkins Seamless Gutters provides the best in gutter service, installation and repair in Marion County, Florida and beyond. Click here to see photos of our work." />

                <meta property="og:description" content="Watkins Seamless Gutters provides the best in gutter service, installation and repair in Marion County, Florida and beyond. Click here to see photos of our work." />
                <meta property="og:title" content="Gallery | Marion County, FL | Watkins Seamless Gutters" />

                <link rel="canonical" href="https://www.watkinsgutters.com/gallery" />

                <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "LocalBusiness",
                    "name": "Watkins Seamless Gutters",
                    "url": "https://www.watkinsgutters.com/gallery",
                    "telephone": "352-812-0831",
                    "email": "GuttersByWatkins@gmail.com",
                    "description": "Watkins Seamless Gutters provides seamless gutter installation and repair services in Sumter, Marion, and Lake Counties, Florida. Click here to see photos of our work.",
                    "image": [
                        "https://www.watkinsgutters.com/gallery/gallery1.webp",
                        "https://www.watkinsgutters.com/gallery/gallery2.webp",
                        "https://www.watkinsgutters.com/gallery/gallery3.webp",
                        "https://www.watkinsgutters.com/gallery/gallery4.webp",
                        "https://www.watkinsgutters.com/gallery/gallery5.webp",
                        "https://www.watkinsgutters.com/gallery/gallery6.webp",
                        "https://www.watkinsgutters.com/gallery/gallery7.webp",
                        "https://www.watkinsgutters.com/gallery/gallery8.webp",
                        "https://www.watkinsgutters.com/gallery/gallery9.webp",
                        "https://www.watkinsgutters.com/gallery/gallery10.webp",
                        "https://www.watkinsgutters.com/gallery/gallery11.webp",
                        "https://www.watkinsgutters.com/gallery/gallery12.webp"
                    ],
                    "areaServed": {
                    "@type": "Place",
                    "name": [
                        "Sumter County, FL", 
                        "Marion County, FL", 
                        "Lake County, FL", 
                        "Ocala, FL", 
                        "Ocklawaha, FL",  
                        "Summerfield, FL",
                        "Leesburg, FL", 
                        "The Villages, FL"
                    ]
                    },
                    "address": {
                        "@type": "PostalAddress",
                        "addressLocality": "Marion County",
                        "addressRegion": "FL",
                        "addressCountry": "US"
                    },
                    "sameAs": [
                        "https://www.facebook.com/GuttersByWatkins"
                    ],
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "telephone": "352-812-0831",
                        "contactType": "Customer Service",
                        "areaServed": [
                            "Sumter County, FL", 
                            "Marion County, FL", 
                            "Lake County, FL", 
                            "Ocala, FL", 
                            "Ocklawaha, FL",  
                            "Summerfield, FL",
                            "Leesburg, FL", 
                            "The Villages, FL"
                        ],
                        "availableLanguage": ["English"]
                    }
                })}
                </script>
            </Helmet>


            <div className='page-wrapper'>
                <div className='page-content-container'>
                    <div className='content-wrapper'>
                        <div className='page-content'>
                            <div className='page-content-wrapper'>
                                <div className='page-title-wrapper'>
                                    <div className='page-title'>
                                        <h1>See Photos of Our Work Here!</h1>
                                    </div>
                                    <div className='page-subtitle'>
                                        <p>
                                            Following is a small sample of the hundreds and hundreds of installations we have done over the years.
                                            <br/><br/>
                                            Frankly, one gutter install looks pretty much like another, so to save you time we have limited this gallery to a small sample. For a real idea of our capability and customer satisfaction check out the <Link to='/testimonials'>Testimonials</Link> which really address how well we performed.
                                        </p>
                                    </div>
                                    <div className='gallery-row-wrapper'>
                                        <div className='gallery-row'>
                                            <div className='gallery-img-wrapper'>
                                                <div className='gallery-image'>
                                                    <img src={gallery1} alt='Newly installed white gutters on a gray house with a well-maintained lawn under a clear blue sky. A worker is standing on a ladder, performing gutter maintenance or installation near the front corner of the house.' height='416' width='555' />
                                                </div>
                                            </div>
                                            <div className='gallery-img-wrapper'>
                                                <div className='gallery-image'>
                                                    <img src={gallery2} alt="Close-up of sleek, dark-colored gutters installed on the corner of a modern home with a pitched roof, showcasing the clean lines and high-quality materials against a light blue sky." height='416' width='555' />
                                                </div>
                                            </div>
                                            <div className='gallery-img-wrapper'>
                                                <div className='gallery-image'>
                                                    <img src={gallery3} alt="Front view of a modern house featuring newly installed white seamless gutters, with a 'Watkins Seamless Gutters' sign displayed on the lawn. The house has a light blue and white exterior, with a well-maintained yard under a clear blue sky." height='416' width='555' />
                                                </div>
                                            </div>
                                            <div className='gallery-img-wrapper'>
                                                <div className='gallery-image'>
                                                    <img src={gallery4} alt="Front view of a beige house with white seamless gutters installed, featuring a neatly landscaped front yard with white gravel and green plants. A 'Watkins Seamless Gutters' sign is displayed on the lawn, advertising gutter installation services. The sky above is partly cloudy with patches of blue." height='416' width='555' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='gallery-row-wrapper'>
                                        <div className='gallery-row'>
                                            <div className='gallery-img-wrapper'>
                                                <div className='gallery-image'>
                                                    <img src={gallery5} alt="Single-story yellow house with white seamless gutters, featuring a brown shingle roof and a clean driveway. The house has well-maintained shrubs and landscaping in the front yard. The sky above is partly cloudy with a bright blue backdrop." height='416' width='555' />
                                                </div>
                                            </div>
                                            <div className='gallery-img-wrapper'>
                                                <div className='gallery-image'>
                                                    <img src={gallery6} alt="Rear view of a white house with black seamless gutters, featuring a covered patio area with outdoor furniture. The house has a dark shingle roof and is surrounded by a grassy lawn. Trees and a clear blue sky are visible in the background." height='416' width='555' />
                                                </div>
                                            </div>
                                            <div className='gallery-img-wrapper'>
                                                <div className='gallery-image'>
                                                    <img src={gallery7} alt="Close-up of white seamless gutters forming a clean corner joint on a roof, demonstrating precision installation. The background shows part of a shingle roof and trees under a clear sky." height='416' width='555' />
                                                </div>
                                            </div>
                                            <div className='gallery-img-wrapper'>
                                                <div className='gallery-image'>
                                                    <img src={gallery8} alt="Close-up view of a white downspout connected to a white gutter on the corner of a house roof, with a blue sky in the background. The gutter system showcases clean installation along the eaves." height='416' width='555' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='gallery-row-wrapper'>
                                        <div className='gallery-row'>
                                            <div className='gallery-img-wrapper' aria-label='Commercial building with seamless gutters installed along the roofline, under a clear blue sky. A white pickup truck is parked in front.'>
                                                <div className='gallery-image'>
                                                    <img src={gallery9} alt="Side view of a metal building under construction with newly installed gutters along the roof edge. The structure features an open framework and concrete foundation, with a sandy yard and trees in the background. A service vehicle for 'Watkins Seamless Gutters' is visible in the distance." height='416' width='555' />
                                                </div>
                                            </div>
                                            <div className='gallery-img-wrapper'>
                                                <div className='gallery-image'>
                                                    <img src={gallery10} alt="Side view of a dark gray house with white seamless gutters and a screened patio. The house features a clean concrete walkway and a well-maintained lawn, with a light gray shingle roof overhead." height='416' width='555' />
                                                </div>
                                            </div>
                                            <div className='gallery-img-wrapper'>
                                                <div className='gallery-image'>
                                                    <img src={gallery11} alt="RV parked under a metal carport with nearby trees and a gutter system visible along the roofline." height='416' width='555' />
                                                </div>
                                            </div>
                                            <div className='gallery-img-wrapper'>
                                                <div className='gallery-image'>
                                                    <img src={gallery12} alt="Newly installed white gutter along the edge of a roof with dark shingles. The view shows the length of the gutter with neighboring houses and a fenced backyard under a cloudy sky." height='416' width='555' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='video-row-wrapper'>
                                        <div className='video-wrapper'>
                                            <div className='video-container'>
                                            <video
                                                src={companyVideo}
                                                controls
                                                poster={thumbnailImage}
                                                ref={videoRef}
                                                onLoadedMetadata={() => {
                                                  videoRef.current.volume = 0.15;
                                                }}
                                            ></video>
                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Gallery;