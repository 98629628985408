import React, { useState, useRef } from 'react';
import './Contact.css';
import { Helmet } from 'react-helmet-async';
import ContactPageForm from '../../components/ContactPageForm/ContactPageForm';

const Contact = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const recaptchaRef = useRef(null); // Reference for reCAPTCHA
    const [recaptchaVisible, setRecaptchaVisible] = useState(false); // Control reCAPTCHA visibility

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value,
        };
        setFormData(updatedFormData);

        // Show reCAPTCHA only if all required fields are filled
        const allFieldsFilled = Object.values(updatedFormData).every(field => field.trim() !== '');
        setRecaptchaVisible(allFieldsFilled);
    };

    // Form validation
    const validateForm = () => {
        const newErrors = {};
        if (!formData.firstName || formData.firstName.length > 100) {
            newErrors.firstName = 'First name is required and should be less than 100 characters.';
        }
        if (!formData.lastName || formData.lastName.length > 100) {
            newErrors.lastName = 'Last name is required and should be less than 100 characters.';
        }
        if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Valid email is required.';
        }
        if (!formData.phone || !/^\d{10,11}$/.test(formData.phone)) {
            newErrors.phone = 'Phone number is required and should be 10-11 digits.';
        }
        if (!formData.message || formData.message.length > 8000) {
            newErrors.message = 'Message is required and should be less than 8000 characters.';
        }
        return newErrors;
    };

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        if (!recaptchaToken) {
            alert('Please complete the reCAPTCHA verification.');
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...formData,
                    recaptchaToken,
                }),
            });

            if (response.ok) {
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    message: '',
                });
                setErrors({});
                alert('Message sent successfully!');
                recaptchaRef.current.reset();
                setRecaptchaToken('');
                setRecaptchaVisible(false);
            } else {
                const errorData = await response.json();
                alert('Error: ' + errorData.message);
            }
        } catch (err) {
            console.error('Error submitting the form:', err);
            alert('Failed to send the message. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    // Handle reCAPTCHA change
    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    return (
        <>
            <Helmet>
                <title>Contact | Marion County, FL | Watkins Seamless Gutters</title>
                <meta 
                    name="description" 
                    content="Contact Watkins Seamless Gutters for gutter installation, repair and guards in Marion, Lake, and Sumter Counties of Florida." 
                />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Contact | Marion County, FL | Watkins Seamless Gutters" />
                <meta name="twitter:description" content="Contact Watkins Seamless Gutters for gutter installation, repair and guards in Marion, Lake, and Sumter Counties of Florida." />

                <meta property="og:description" content="Contact Watkins Seamless Gutters for gutter installation, repair and guards in Marion, Lake, and Sumter Counties of Florida." />
                <meta property="og:title" content="Contact | Marion County, FL | Watkins Seamless Gutters" />

                <link rel="canonical" href="https://www.watkinsgutters.com/contact" />

                <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "LocalBusiness",
                    "name": "Watkins Seamless Gutters",
                    "url": "https://www.watkinsgutters.com/contact",
                    "telephone": "352-812-0831",
                    "email": "GuttersByWatkins@gmail.com",
                    "address": {
                        "@type": "PostalAddress",
                        "addressLocality": "Marion County",
                        "addressRegion": "FL",
                        "addressCountry": "US"
                    },
                    "sameAs": [
                        "https://www.facebook.com/GuttersByWatkins"
                    ],
                    "areaServed": {
                    "@type": "Place",
                    "name": [
                        "Sumter County, FL", 
                        "Marion County, FL", 
                        "Lake County, FL", 
                        "Ocala, FL", 
                        "Ocklawaha, FL",  
                        "Summerfield, FL",
                        "Leesburg, FL", 
                        "The Villages, FL"
                    ]
                    },
                    "openingHoursSpecification": {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": [
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday"
                        ],
                        "opens": "08:00",
                        "closes": "16:00"
                    },
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "telephone": "352-812-0831",
                        "contactType": "Customer Service",
                        "areaServed": [
                            "Sumter County, FL", 
                            "Marion County, FL", 
                            "Lake County, FL", 
                            "Ocala, FL", 
                            "Ocklawaha, FL",  
                            "Summerfield, FL",
                            "Leesburg, FL", 
                            "The Villages, FL"
                        ],
                        "availableLanguage": ["English"]
                    }
                })}
                </script>

            </Helmet>

            <div className='page-wrapper'>
                <div className='page-content-container'>
                    <div className='content-wrapper'>
                        <div className='page-content'>
                            <div className='page-content-wrapper'>
                                <div className='page-title-wrapper'>
                                    <div className='page-title'>
                                        <h1>Contact Our Gutter Experts Today!</h1>
                                    </div>
                                        <div className='content-columns-wrapper'>
                                            <div className='content-column'>
                                                <div className='section-content' style={{marginTop: 0}}>
                                                    <h2>Contact Information</h2>
                                                </div>
                                                <div className='section-content' style={{marginTop: '30px'}}>
                                                    <h3>Watkins Seamless Gutters</h3>
                                                </div>
                                                <div className='section-content'>
                                                    <p>
                                                        <strong>Phone:</strong>
                                                        &nbsp;
                                                        <strong><a href="tel:352-812-0831" style={{textDecoration: 'none', color: 'inherit'}} aria-label="Call Watkins Seamless Gutters at 352-812-0831">352-812-0831</a></strong>
                                                    </p>
                                                </div>
                                                <div className='section-content'>
                                                    <p>
                                                        <strong>Email:</strong>
                                                        &nbsp;
                                                        <strong><a href="mailto:GuttersByWatkins@gmail.com" style={{textDecoration: 'none', color: 'inherit'}} aria-label="Email Watkins Seamless Gutters at GuttersByWatkins@gmail.com">GuttersByWatkins@gmail.com</a></strong>
                                                    </p>
                                                </div>
                                                <div className='section-content' style={{marginTop: '30px'}}>
                                                    <h2>Our Service Area Includes:</h2>
                                                </div>
                                                <div className='section-content'>
                                                    <ul style={{display: 'flex', flexWrap: 'wrap', maxHeight: '48%', maxWidth: '70%', justifyContent: 'space-between'}}>
                                                        <li>The Villages</li>
                                                        <li>Sumter County</li>
                                                        <li>Marion County</li>
                                                        <li>Lake County</li>
                                                    </ul>
                                                </div>
                                                <div className='section-content' style={{marginTop: '30px'}}>
                                                    <h2>Business Hours</h2>
                                                </div>
                                                <div className='section-content'>
                                                    <p>
                                                        <strong>Mon – Fri:</strong>
                                                        &nbsp;
                                                        8 a.m. – 4 p.m.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='form-background'>
                                                <div className='form-container'>
                                                    <h3 className="form-title">Contact Us</h3>
                                                    <div className='form-content-wrapper'>
                                                        <ContactPageForm apiUrl={process.env.REACT_APP_API_URL} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ border: '0', margin: '0', padding: '0' }}>
                        <iframe 
                            title="Map of service areas: Marion, Lake, and Sumter Counties, and The Villages Area, FL" 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d708740.8038251897!2d-81.91497834790687!3d29.043174693478658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e7c99dc26fdb93%3A0x3f8b33494de838dd!2s10809%20SE%20Hwy%20464C%2C%20Ocklawaha%2C%20FL%2032179!5e0!3m2!1sen!2sus!4v1741355417305!5m2!1sen!2sus" 
                            style={{ border: '0', marginBottom: '-0.3rem', padding: '0' }} 
                            width="100%" 
                            height="230"  
                            allowFullScreen=""
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"
                            aria-label="Map of service areas: Marion, Lake, and Sumter Counties, and The Villages Area, FL"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
