import React from 'react';
import './OurReviews.css'; // Import the stylesheet
import { Helmet } from 'react-helmet-async';

const OurReviews = () => {

    return (
        <>
            <Helmet>
                <title>Reviews | Marion County, FL | Watkins Seamless Gutters</title>
                <meta 
                    name="description" 
                    content="Watkins Seamless Gutters is known for the highest quality of gutter service in Lake, Sumter and Marion Counties of Florida. Click here to read our reviews." 
                />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Reviews | Marion County, FL | Watkins Seamless Gutters" />
                <meta name="twitter:description" content="Watkins Seamless Gutters is known for the highest quality of gutter service in Lake, Sumter and Marion Counties of Florida. Click here to read our reviews." />

                <meta property="og:description" content="Watkins Seamless Gutters is known for the highest quality of gutter service in Lake, Sumter and Marion Counties of Florida. Click here to read our reviews." />
                <meta property="og:title" content="Reviews | Marion County, FL | Watkins Seamless Gutters" />

                <link rel="canonical" href="https://www.watkinsgutters.com/testimonials" />

                <script type="application/ld+json">
                    {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "LocalBusiness",
                    "name": "Watkins Seamless Gutters",
                    "url": "https://www.watkinsgutters.com/testimonials",
                    "telephone": "352-812-0831",
                    "email": "GuttersByWatkins@gmail.com",
                    "address": {
                        "@type": "PostalAddress",
                        "addressLocality": "Marion County",
                        "addressRegion": "FL",
                        "addressCountry": "US"
                    },
                    "areaServed": {
                        "@type": "Place",
                        "name": [
                            "Sumter County, FL", 
                            "Marion County, FL", 
                            "Lake County, FL", 
                            "Ocala, FL", 
                            "Ocklawaha, FL",  
                            "Summerfield, FL",
                            "Leesburg, FL", 
                            "The Villages, FL"
                        ]
                    },
                    "sameAs": [
                        "https://www.facebook.com/GuttersByWatkins"
                    ],
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "5",
                        "reviewCount": "5"
                    },
                    "review": [
                        {
                            "@type": "Review",
                            "author": {
                                "@type": "Person",
                                "name": "Customer"
                            },
                            "reviewRating": {
                                "@type": "Rating",
                                "ratingValue": "5"
                            },
                            "reviewBody": "Watkins Seamless Gutters responded quickly and were on site for a small repair after other companies didn't return my call."
                        },
                        {
                            "@type": "Review",
                            "author": {
                                "@type": "Person",
                                "name": "Customer"
                            },
                            "reviewRating": {
                                "@type": "Rating",
                                "ratingValue": "5"
                            },
                            "reviewBody": "Very high level work ethic and did what they said when they said. Quality people. I would tell anyone that you cannot go wrong with Watkins. Bonus, they were by far the lowest quote."
                        },
                        {
                            "@type": "Review",
                            "author": {
                                "@type": "Person",
                                "name": "Customer"
                            },
                            "reviewRating": {
                                "@type": "Rating",
                                "ratingValue": "5"
                            },
                            "reviewBody": "Outstanding service in a timely manner with a good price point. Great professionals to work with."
                        },
                        {
                            "@type": "Review",
                            "author": {
                                "@type": "Person",
                                "name": "Customer"
                            },
                            "reviewRating": {
                                "@type": "Rating",
                                "ratingValue": "5"
                            },
                            "reviewBody": "Great people to deal with. They arrived on time and did the job at more than half the cost of other companies."
                        },
                        {
                            "@type": "Review",
                            "author": {
                                "@type": "Person",
                                "name": "Customer"
                            },
                            "reviewRating": {
                                "@type": "Rating",
                                "ratingValue": "5"
                            },
                            "reviewBody": "They were quick to schedule, showed up as expected, and worked carefully to avoid damaging the landscape."
                        }
                    ]
                    })}
                </script>
            </Helmet>

            <div className='page-wrapper'>
                <div className='page-content-container'>
                    <div className='content-wrapper'>
                        <div className='page-content'>
                            <div className='page-content-wrapper'>
                                <div className='page-title-wrapper'>
                                    <div className='page-title'>
                                        <h1>Top Rated Reviews of Our Work!</h1>
                                    </div>
                                </div>
                                <div className='testimonial-row-wrapper'>
                                    <div className='testimonial-row'>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    I tried some other companies first but no one returned my call for a small repair. Watkins replied and were on site quickly.<br/><br/>
                                                    <strong>Donald C.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    I had to have gutter repaired because the original builder did not put in enough downspouts. I added two downspouts and the colors were a perfect match! Quick and efficient, very understanding and fixed the problem that others seemed to shy away from.<br/><br/>
                                                    <strong>Rebecca T.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Very high level work ethic and did what they said when they said. Quality people. I would tell anyone that you cannot go wrong with Watkins. Bonus, they were by far the lowest quote.<br/><br/>
                                                    <strong>Steve B.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    I got three quotes for my gutter project and chose Watkins. A family owned and managed company. Very friendly and very efficient. Highly recommended.<br/><br/>
                                                    <strong>Scott H.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='testimonial-row'>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Outstanding service in a timely manner with a good price point. Need more professionals like these folks in the business…job well done. Thx.<br/><br/>
                                                    <strong>Sam G.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Great people to deal with. They come when they say they are going to and ready to do the job at more than half the cost of other gutter people. I will hire them again…you won't go wrong! No extra charges.<br/><br/>
                                                    <strong>Kathleen S.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Excellent work, very experienced and efficient. They cleaned up after themselves, you would not know they were even here.<br/><br/>
                                                    <strong>Pat M.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Great to work with. They were quick to get us scheduled after we signed the contract. They showed up as expected and worked very clean and were very careful not to damage our landscape.<br/><br/>
                                                    <strong>Patty L.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>                            
                                    <div className='testimonial-row'>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Top notch folks! I cannot tell you how professional they are and very reasonably priced!<br/><br/>
                                                    <strong>Kent A.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Super.<br/><br/>
                                                    <strong>Bill H.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Very professional, hard working, and on time.<br/><br/>
                                                    <strong>Chris B.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Husband and wife are owners and do all of the work. Very conscientious.<br/><br/>
                                                    <strong>James P.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='testimonial-row'>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Came on time. Neat work. Gutters look great. I would highly recommend them to anyone looking for gutter work. Can't say enough good things about them.<br/><br/>
                                                    <strong>Joan T.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    They are great people.<br/><br/>
                                                    <strong>Steve L.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    They are 100% professional, easy to work with, did a great job, and will use them for every job I can.<br/><br/>
                                                    <strong>Marsha H.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Great experience.<br/><br/>
                                                    <strong>Chip V.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='testimonial-row'>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Did a great job, very professional.<br/><br/>
                                                    <strong>Art J.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Very professional and knowledgeable.<br/><br/>
                                                    <strong>Jason D.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    Ron & Renee are my HEROES !!!!!! They showed up on time – gave me an awesome quote – then came back three days later to do an excellent job. They are definitely the best of the best and I highly recommend them. Should the need arise, they will be getting all of our future business – you just don't get any better than those two!!!<br/><br/>
                                                    <strong>Kathy L.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='review-card'>
                                            <div className='review-content'>
                                                <p>⭐⭐⭐⭐⭐</p>
                                            </div>
                                            <div className='review-content'>
                                                <p>
                                                    I checked their reviews on Home Advisor and Google and was astounded to discover virtually a perfect 5-star rating from close to 80 reviewers. I've never seen a vendor rated so highly and really didn't believe it, but decided to give them a try anyway. We were not disappointed and were highly impressed with the Watkins team, and recommended them to two other families that used them and they were as impressed as we were! Their competitive, hard-working, courteous, and friendly nature resulted in extremely satisfactory and on-time work. On a scale of 1 to 5, I would have to give them a 7.<br/><br/>
                                                    <strong>Ken B.</strong><br/>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OurReviews;
